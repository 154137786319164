import { firebase, provider } from '@/firebase'
import router from '@/router'

const state = {
  uid: null,
  credential: null
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {String} ユーザーID
   */
  uid: state => state.uid,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 認証情報
   */
  credential: state => state.credential
}

const mutations = {
  /**
    * ユーザーIDをstateにセット
    * @param {Object} state 暗黙的に受け取るstate
    * @param {String} uid ユーザーID
    */
  setUID: (state, uid) => {
    state.uid = uid
  },
  /**
   * ユーザーの認証情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} credential ユーザーの認証情報
   */
  setCredential: (state, credential) => {
    state.credential = credential
  }
}

const actions = {
  /**
    * ユーザ情報が更新時、uidを更新する
    */
  onAuth ({ commit, rootGetters, dispatch }) {
    firebase.auth().onAuthStateChanged(async auth => {
      commit('setAuthProcessing', true, { root: true })

      // auth情報をセット
      commit('setUID', auth ? auth.uid : null)

      if (auth) {
        // ユーザー情報の取得
        const user = await dispatch('users/getUser', auth.uid, { root: true })

        // 認証拒否：admin以外のアカウントはログインさせない
        if (!user || user.authority !== 'admin') {
          const msg = '管理者画面は管理者権限を持つユーザーしか利用できません'
          const type = 'error'
          const to = 'login'
          dispatch('denyAuthentication', { msg: msg, type: type, to: to })
          return 0
        }
      }

      const redirectPath = rootGetters.redirectPath
      const isFirstAccess = rootGetters.isFirstAccess

      commit('setAuthProcessing', false, { root: true })

      // 初回アクセスの場合はリダイレクトさせる
      if (isFirstAccess) router.push({ path: redirectPath, query: { auth: new Date().getTime() } })
    })
  },
  /**
   * 認証を拒否する
   * @param {Object} payload 遷移先とテロップの内容
   * @param {String} payload.msg テロップの内容
   * @param {String} payload.type テロップの色 success, warning, error
   * @param {String} payload.to 遷移先
   */
  async denyAuthentication ({ commit, dispatch }, payload) {
    // サインアウト
    await dispatch('signout')

    // テロップ処理
    commit('setTelop', { show: true, msg: payload.msg, type: payload.type }, { root: true })
    router.push({ name: payload.to })
  },
  /**
   * サインイン
   * @param {String} sns SNS認証のプロバイダー名 google, facebook, twitter
   * @return {Object} authのユーザー情報（キャンセル時はnullを返却）
   */
  async signin ({ commit }, sns) {
    try {
      const auth = await firebase.auth().signInWithPopup(provider[sns])
      commit('setCredential', auth.credential)
      return auth.user
    } catch (error) {
      // キャンセルした場合は、nullを返却
      if (error.code !== 'auth/user-cancelled') return resolve(null)

      // それ以外のエラーの場合はエラー画面に遷移させる
      router.push({ name: 'error' })
    }
  },
  /**
   * サインアウト
   */
  async signout ({ commit }) {
    try {
      await firebase.auth().signOut()
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 再認証を行う
   * @return {null} 返戻値無し
   */
  async reauthentication ({ commit, getters }) {
    const user = firebase.auth().currentUser
    const credential = getters.credential
    try {
      await user.reauthenticateWithCredential(credential)
      return { status: 'success' }
    } catch {
      return { status: 'error', error: error.code }
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
