import { firestore } from '@/firebase'
import router from '@/router'

const state = {
  // 全ユーザーのオブジェクト一覧
  // { uid: {}, uid: {}, ... }
  users: {}
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  user: state => uid => state.users[uid] ? state.users[uid] : null
}

const mutations = {
  /**
   * ユーザー情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid ユーザーID
   * @param {Object} payload.user ユーザーのオブジェクト
   */
  setUser: (state, payload) => {
    state.users[payload.uid] = payload.user
  },
  /**
   * ユーザー情報を更新
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} payload 引数の情報
   * @param {String} payload.uid 更新するユーザーのID
   * @param {Object} payload.params 更新するユーザー情報の項目
   */
  updateUser: (state, payload) => {
    Object.keys(payload.params).map(key => {
      if (state.users[payload.uid][key] !== undefined) {
        state.users[payload.uid][key] = payload.params[key]
      } else {
        // プロパティ追加の場合、computedで検知されるようObject.assign()を使う
        state.users[payload.uid] = Object.assign(state.users[payload.uid], { [key]: payload.params[key] })
      }
    })
  }
}

const actions = {
  /**
   * ユーザー情報を取得
   * @param {String} uid ユーザーID
   * @return {Object} ユーザー情報
   */
  async getUser ({ commit }, uid) {
    try {
      const doc = await firestore
        .collection('users')
        .doc(uid)
        .get()
      const user = doc.exists ? doc.data() : null
      if (user) commit('setUser', { uid: uid, user: user })
      return user
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ユーザー情報を更新
   * @param {Object} payload ユーザー情報
   * @param {String} payload.uid 更新するユーザーのID
   * @param {Object} payload.params 更新情報
   */
  async updateUser ({ commit }, payload) {
    try {
      await firestore
        .collection('users')
        .doc(payload.uid)
        .update(payload.params)
      commit('updateUser', { uid: payload.uid, params: payload.params })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
