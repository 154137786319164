import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'

import store from '@/store'
import { firebase } from '@/firebase'

/**
 * vue-router v3.1.0から、同一画面に遷移するとコンソールエラーが発生するようになった
 * push関数にエラーハンドリングを追加して、デフォルトの関数を上書きすることで対応
 * @see https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: '/setting',
      name: 'setting',
      component: loadComponent('Setting.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: loadComponent('Login.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: loadComponent('News.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/news/list',
      name: 'news_list',
      component: loadComponent('NewsList.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/news/add',
      name: 'news_add',
      component: loadComponent('NewsAdd.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/news/:aid/edit',
      name: 'news_edit',
      component: loadComponent('NewsEdit.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/about',
      name: 'about',
      component: loadComponent('About.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/error',
      name: 'error',
      component: loadComponent('Error.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: loadComponent('NotFound.vue'),
      meta: { autoTransition: true }
    },
    {
      path: '**',
      redirect: { name: 'notfound' }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

/**
 * viewsのファイルをロード
 * @param {String} name viewsのファイル名
 * @return {Object} 遅延ロードしたコンポーネント
 */
function loadComponent (name) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${name}`)
}

/**
 * ページ遷移の分岐処理
 */
router.beforeEach((to, from, next) => {
  const autoTransition = to.matched.some(record => record.meta.autoTransition)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  // 別画面に遷移する時、processingを表示状態にする
  if (to.name !== from.name) store.commit('setProcessing', true)

  // 判定用のデータ取得
  const auth = firebase.auth().currentUser
  const uid = auth ? auth.uid : null
  const user = uid ? store.getters['users/user'](uid) : null
  const isAuthProcessing = store.getters.isAuthProcessing

  // 認証、ユーザー取得状況に応じてページ遷移
  // 初回アクセス時、onAuthの処理が終わる前にrouterが動くため、初回処理が終わるまで遷移をループさせる
  if (isAuthProcessing || autoTransition) {
    // 初回アクセス時 または 無条件で遷移されるページの場合
    next()
  } else if (user && user.authority === 'admin') {
    // 管理者権限がある場合
    // アクセス不要ページであれば遷移先をhomeに変える
    ['login'].includes(to.name) ? next({ name: 'home' }) : next()
  } else {
    // 管理者権限がない場合
    requiresAuth ? next({ name: 'login' }) : next()
  }
})

export default router
