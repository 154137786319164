import { firebase } from '@/firebase'
import router from '@/router'

// functionsがあるリージョン
const REGION = 'asia-northeast1'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * adminアカウントにカスタムクレームを付与する
   * @param {String} uid ユーザーのID
   */
  async addCustomClaim ({ commit }, uid) {
    const send = firebase.functions(REGION).httpsCallable('addCustomClaim')
    try {
      await send({ uid: uid })
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
