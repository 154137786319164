import { firestore, convertDateToTimestamp } from '@/firebase'
import router from '@/router'

const state = {
  articles: []
}

const getters = {
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @return {Object} 順序が担保されたニュース情報一覧
   */
  articles: state => state.articles,
  /**
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} aid ニュースID
   * @return {Object} ニュース情報
   */
  article: state => aid => state.articles.find(article => article.aid === aid) || null
}

const mutations = {
  /**
   * ニュース情報をstateにセット
   * @param {Object} state 暗黙的に受け取るstate
   * @param {Object} newArticle ニュース情報
   */
  setArticle: (state, newArticle) => {
    // 取得済み：ニュース情報を上書き
    // 未取得：新規ニュースをセット
    const index = state.articles.findIndex(article => article.aid === newArticle.aid)
    index === -1 ? state.articles.push(newArticle) : state.articles.splice(index, 1, newArticle)

    // 公開日時で降順ソート
    state.articles.sort((a, b) => a.postedAt > b.postedAt ? -1 : 1)
  },
  /**
   * ニュース情報をstoreから削除する
   * @param {Object} state 暗黙的に受け取るstate
   * @param {String} aid 削除するニュースのID
   */
  deleteArticle: (state, aid) => {
    state.sortedArticles = state.sortedArticles.filter(article => article.aid !== aid)
  }
}

const actions = {
  /**
   * ニュース情報を追加
   * @param {Object} params ニュース情報
   * @return {Object} ニュース情報
   */
  async addArticle ({ commit }, params) {
    try {
      const ref = await firestore
        .collection('articles')
        .add(params)

      // Date型をfirestoreのTimestamp型としてstoreに保存するため変換
      params.postedAt = convertDateToTimestamp(params.postedAt)
      const article = Object.assign(params, { aid: ref.id })
      commit('setArticle', article)
      return article
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ニュース情報を更新
   * @param {Object} payload ニュース情報
   * @param {String} payload.aid ニュースのID
   * @param {Object} payload.params ニュースの更新情報
   */
  async updateArticle ({ commit }, payload) {
    try {
      await firestore
        .collection('articles')
        .doc(payload.aid)
        .update(payload.params)

      // Date型をfirestoreのTimestamp型としてstoreに保存するため変換
      payload.params.postedAt = convertDateToTimestamp(payload.params.postedAt)
      const article = Object.assign(payload.params, { aid: payload.aid })
      commit('setArticle', article)
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ニュース情報一覧を取得
   */
  async getArticles ({ commit }) {
    try {
      const snapshot = await firestore
        .collection('articles')
        .where('isDeleted', '==', false)
        .get()
      snapshot.forEach(doc => {
        const article = Object.assign(doc.data(), { aid: doc.id })
        commit('setArticle', article)
      })
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * ニュース情報を取得
   * @param {String} aid ニュース情報のID
   */
  async getArticle ({ commit }, aid) {
    try {
      const doc = await firestore
        .collection('articles')
        .doc(aid)
        .get()
      const article = doc.exists ? Object.assign(doc.data(), { aid: doc.id }) : null
      if (article) commit('setArticle', article)
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
