import Firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/firestore'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
}

// 利用するプロバイダ一覧
const provider = {
  google: new Firebase.auth.GoogleAuthProvider(),
  facebook: new Firebase.auth.FacebookAuthProvider(),
  twitter: new Firebase.auth.TwitterAuthProvider()
}

const firebase = Firebase.initializeApp(config)
firebase.auth().setPersistence(Firebase.auth.Auth.Persistence.LOCAL)

const firestore = firebase.firestore()

// firestoreのTimestamp型
const timestamp = Firebase.firestore.Timestamp

/**
 * Date型をfirestoreのTimestamp型に変換する
 * @param {Date} date 変換するDate型
 * @return {Firebase.firestore.Timestamp} firestoreのTimestamp型
 */
const convertDateToTimestamp = date => {
  return timestamp.fromDate(date)
}

export { firebase, firestore, provider, convertDateToTimestamp }
