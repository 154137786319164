<template>
  <transition name="fade">
    <div
      :class="telop.type === 'success' ? 'telop'
        : telop.type === 'warning' ? 'telop--yellow'
          : telop.type === 'error' ? 'telop--red' : ''">
      <p class="telop__text">{{ telop.msg }}</p>
    </div>
  </transition>
</template>

<script>
export default {
  computed: {
    /**
     * @return {Object} テロップの情報
     */
    telop () {
      return this.$store.getters.telop
    }
  },
  mounted () {
    // 3秒後にテロップを消す
    setTimeout(() => {
      this.$store.commit('setTelop', { show: false, msg: '', type: '' })
    }, 3000)
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.telop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup_zindex;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 40px;
  background-color: $green_color;
  &--yellow {
    @extend .telop;
    background-color: $yellow_color;
  }
  &--red {
    @extend .telop;
    background-color: $red_color;
  }
  &__text {
    max-width: 100%;
    padding: 0 $padding_width;
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $white_color;
    text-align: center;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}

// アニメーション用
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
