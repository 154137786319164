import { firebase } from '@/firebase'
import router from '@/router'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  /**
   * storageへファイルをアップロードする
   * @param {Object} payload アップロードファイル情報
   * @param {String} payload.path アップロード先のパス
   * @param {String} payload.file アップロードするファイル
   * @return {String} ダウンロード用のURL
   */
  async uploadFile ({ commit }, payload) {
    const ref = firebase.storage().ref().child(payload.path)
    try {
      await ref.put(payload.file)
      const url = await ref.getDownloadURL()
      return url
    } catch {
      router.push({ name: 'error' })
    }
  },
  /**
   * 指定したURLのファイルを削除する
   * @param {String} url 削除するファイルのURL
   */
  async deleteFile ({ commit }, url) {
    const ref = firebase.storage().refFromURL(url)
    try {
      await ref.delete()
    } catch {
      router.push({ name: 'error' })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
