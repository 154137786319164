<template>
  <header class="header" v-if="isDisplay">
    <v-btn text class="header__btn" v-if="type.includes('title')" @click="back()">
      <v-icon class="header__btn__icon">arrow_back</v-icon>
    </v-btn>
    <v-img class="header__logo" v-if="type.includes('logo')" :src="require('@/assets/img/logo.png')" contain />
    <p class="header__title" v-if="type.includes('title')">{{ title }}</p>
  </header>
</template>

<script>
// ヘッダーを表示させないページ名
const NOT_DISPLAY = [
  'error',
  'notfound',
  'login'
]

// ヘッダーの詳細
// type: back, logo, title
const HEADER_DETAIL = {
  login: {
    type: ['logo']
  },
  home: {
    type: ['logo']
  },
  about: {
    type: ['back', 'title'],
    title: '基本情報'
  },
  news: {
    type: ['back', 'title'],
    title: 'ニュース情報'
  },
  news_add: {
    type: ['back', 'title'],
    title: 'ニュースの追加'
  },
  news_list: {
    type: ['back', 'title'],
    title: 'ニュースの編集・削除'
  },
  news_edit: {
    type: ['back', 'title'],
    title: 'ニュースの編集'
  },
  setting: {
    type: ['back', 'title'],
    title: '設定'
  }
  // 戻るボタンのみ表示の場合
  // home: {
  //   type: ['back']
  // }
  // 戻るボタンとタイトルを表示の場合
  // タイトル表示の際はオブジェクトにtitleを保持する必要あり
  // home: {
  //   type: ['back', 'title'],
  //   title: 'ホーム'
  // }
}

export default {
  computed: {
    /**
     * @return {Boolean} ヘッダーを表示させるかどうか
     */
    isDisplay () {
      return !NOT_DISPLAY.includes(this.$route.name)
    },
    /**
     * @return {String} router.jsで定義された名前
     */
    pageName () {
      return this.$route.name
    },
    /**
     * @return {String} ヘッダーのタイトル
     */
    title () {
      return this.pageName && HEADER_DETAIL[this.pageName].title ? HEADER_DETAIL[this.pageName].title : ''
    },
    /**
     * @return {String} ヘッダーのタイプ
     */
    type () {
      return this.pageName && HEADER_DETAIL[this.pageName].type ? HEADER_DETAIL[this.pageName].type : ''
    }
  },
  methods: {
    /**
     * 前のページに戻る
     */
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.header {
  position: fixed;
  top: 0;
  z-index: $header_zindex;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: $header_height;
  text-align: center;
  background-color: $white_color;
  &__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10px;
    margin: auto;
    &.v-btn:not(.v-btn--round).v-size--default {
      min-width: auto;
      height: 20px;
      padding: 0;
      color: $black_color;
    }
    &__icon {
      width: 20px;
      height: 20px;
      &.v-icon {
        font-size: 2rem;
      }
    }
  }
  &__logo {
    width: 120px;
    max-height: calc(#{$header_height} - 10px);
    margin: 0 auto;
  }
  &__title {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
</style>
