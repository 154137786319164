<template>
  <div class="home" v-if="!isProcessing">
    <div class="home__init" v-if="user ? !user.isInitialized : false">
      <div class="home__init__text">
        ボタンを押下して<br>
        初期設定を行ってください
      </div>
      <v-btn class="home__init__btn" outlined @click="initialize()">初期設定</v-btn>
    </div>
    <div class="home__menu" v-for="item in items" :key="item.title">
      <home-menu :item="item" />
    </div>
  </div>
</template>

<script>
import HomeMenu from '@/components/common/menu.vue'

export default {
  name: 'home',
  components: { HomeMenu },
  data () {
    return {
      items: [
        { title: '基本情報', content: '会社の基本情報を確認する', to: 'about' },
        { title: 'ニュース', content: 'ニュースの追加、編集を行う', to: 'news' },
        { title: '設定', content: 'ログアウトを行う', to: 'setting' }
      ]
    }
  },
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['auth/uid']
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * 管理者アカウントの初期化を行う
     */
    async initialize () {
      this.$store.commit('setProcessing', true)

      await this.$store.dispatch('functions/addCustomClaim', this.uid)
      await this.$store.dispatch('auth/reauthentication')
      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: { isInitialized: true, updatedAt: new Date() } })

      this.$store.commit('setProcessing', false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.home {
  margin-top: $header_height;
  &__init {
    margin: 10px 0 0;
    &__text {
      font-size: 1.4rem;
      line-height: 16px;
      color: $gray_darken_color;
      text-align: center;
    }
    &__btn {
      top: 50%;
      left: 50%;
      width: 120px;
      margin: 10px 0;
      font-size: 1.2rem;
      border: 2px solid $orange_color;
      border-radius: 15px;
      box-shadow: none;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      &.v-btn:not(.v-btn--round).v-size--default {
        height: 40px;
      }
      &.theme--light.v-btn {
        color: $orange_color;
      }
    }
  }
}
</style>
